exports.components = {
  "component---src-layouts-booking-tsx": () => import("./../../../src/layouts/booking.tsx" /* webpackChunkName: "component---src-layouts-booking-tsx" */),
  "component---src-layouts-cms-tsx": () => import("./../../../src/layouts/cms.tsx" /* webpackChunkName: "component---src-layouts-cms-tsx" */),
  "component---src-layouts-model-tsx": () => import("./../../../src/layouts/model.tsx" /* webpackChunkName: "component---src-layouts-model-tsx" */),
  "component---src-layouts-models-tsx": () => import("./../../../src/layouts/models.tsx" /* webpackChunkName: "component---src-layouts-models-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activate-account-tsx": () => import("./../../../src/pages/activateAccount.tsx" /* webpackChunkName: "component---src-pages-activate-account-tsx" */),
  "component---src-pages-customer-account-tsx": () => import("./../../../src/pages/customerAccount.tsx" /* webpackChunkName: "component---src-pages-customer-account-tsx" */),
  "component---src-pages-set-password-tsx": () => import("./../../../src/pages/setPassword.tsx" /* webpackChunkName: "component---src-pages-set-password-tsx" */)
}

